<template>
  <section>
    <van-collapse v-model="activeName" accordion>
      <van-collapse-item
        v-for="(item, index) in list"
        :key="index"
        :title="index + 1 + ') ' + item.title"
        :name="index"
      >
        <div v-html="item.answer"></div
      ></van-collapse-item>
    </van-collapse>
  </section>
</template>
<script>
import basePage from '@/mixins/basePage'
import { setDocTitle } from '@/utils/pages'
import { Collapse, CollapseItem } from 'vant'
const jsonZhMap = {
  default: './json/xpilot-qa.json',
  果数测绘1: './json/果树测绘-帮助一.json',
  果数测绘2: './json/果数测绘-帮助二.json'
}
const jsonEnMap = {
  default: './json/xpilot-qa-en.json',
  果数测绘1: './json/果树测绘-帮助一-en.json',
}
const jsonMap = {
  zh: jsonZhMap,
  en: jsonEnMap
}
export default {
  config: {
    title: 'XAG'
  },
  mixins: [basePage],
  components: {
    vanCollapse: Collapse,
    vanCollapseItem: CollapseItem
  },
  data() {
    return {
      activeName: 0,
      list: []
    }
  },
  methods: {
    getQuerySrc() {
      if (this.$route.query.src) return this.$route.query.src
      try {
        const { lang, type } = this.$route.params
        if (type === 'en' && !lang) return jsonEnMap.default
        return jsonMap[lang || 'zh'][type || 'default']
      } catch (error) {
        return jsonZhMap.default
      }
    },
    async init(){
      const { title, items } = await this.$_fetchDataWidthUi({
        url: this.getQuerySrc()
      })
      this.list = items
      setDocTitle(title)
    }
  },
  mounted() {
    this.init()
  },
  beforeRouteUpdate(to, form, next) {
    next()
    this.init()
  }
}
</script>
<style lang="scss" scoped></style>
